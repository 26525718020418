import React, {useCallback, useEffect, useState} from "react";
import PaymentListPresenter from "./PaymentListPresenter";
import {toast} from "react-toastify";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
    CREATE_PURCHASE_COUPON_EXCEL,
    PAYMENT_CONFIRM_AUTH_CODE,
    PAYMENT_REQUEST_AUTH_CODE
} from "../../graphql/payment/mutation";
import {COUPON_PAYMENT_LIST} from "../../graphql/payment/query";
import {removeHyphen, toastNewLine} from "../../utils/commons";

const PaymentListContainer = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authNumber, setAuthNumber] = useState('');
    const [seconds, setSeconds] = useState(null);
    const [isAuth, setIsAuth] = useState(false);

    const [couponPaymentList, { data, loading, error }] = useLazyQuery(COUPON_PAYMENT_LIST);
    const [paymentRequestAuthCode] = useMutation(PAYMENT_REQUEST_AUTH_CODE);
    const [paymentConfirmAuthCode] = useMutation(PAYMENT_CONFIRM_AUTH_CODE);
    const [createPurchaseCouponExcel] = useMutation(CREATE_PURCHASE_COUPON_EXCEL);

    // 휴대폰 인증 유효시간 count
    useEffect(() => {
        if (seconds >= 0 && !isAuth) {
            const timer = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(number => number - 1);
                } else if (isAuth) {
                    setSeconds(null);
                    clearInterval(timer);
                } else if (seconds === 0) {
                    toast.error('인증번호 유효시간이 초과하였습니다. 인증번호를 다시 전송해주세요.');
                    setSeconds(null);
                    clearInterval(timer);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds, isAuth]);

    const sendAuthCode = useCallback(async () => {
        if (phoneNumber === '') {
            toast.error("휴대폰 번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.info("인증을 완료했습니다.");
            return;
        }
        // if (!!seconds) return;
        try {
            const {data} = await paymentRequestAuthCode({
                variables: {
                    cellphone: removeHyphen(phoneNumber)
                }
            });

            if (data.paymentRequestAuthCode) {
                setSeconds(180);
                toast.info("인증번호 6자리를 입력해주세요.");
                setSeconds(180);
            }
        } catch (e) {
            console.info(e);
        }
    }, [phoneNumber, seconds, isAuth]);

    const confirmAuthCode = useCallback(async () => {
        if (authNumber === '') {
            toast.error("인증번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.info("인증을 완료했습니다.");
            return;
        }
        if (seconds > 0 && !isAuth) {
            try {
                const {data} = await paymentConfirmAuthCode({
                    variables: {
                        cellphone: removeHyphen(phoneNumber),
                        authCode: authNumber
                    }
                });

                if (data.paymentConfirmAuthCode) {
                    toast.info('인증이 완료되었습니다.');
                    setSeconds(null);
                    setIsAuth(true);
                }

            } catch (e) {
                toast.error(e.message);
                console.info(e.message);
            }
        }
    }, [authNumber, seconds, phoneNumber, isAuth]);

    const handleLookUp = useCallback(async () => {
        if (!isAuth) {
            toast.error('휴대폰 인증을 완료해주세요.');
            return;
        }
        try {
            const res = await couponPaymentList({
                variables: {
                    cellPhone: removeHyphen(phoneNumber)
                }
            });

            if (!res.data && res.error.message) {
                toast.error(toastNewLine(res.error.message));
            }

        } catch (e) {
            toast.error(toastNewLine(e.message));
            console.info(e.message);
        }
    }, [phoneNumber, isAuth]);

    const couponDownload = useCallback(async cbId => {
        try {
            const { data } = await createPurchaseCouponExcel({
                variables: {
                    cbId: cbId
                }
            });

            if (data?.createPurchaseCouponExcel?.couponExcel) {
                window.open(data.createPurchaseCouponExcel.couponExcel, '_blank');
            }
        } catch(e) {
            console.info(e.message);
            toast.error(e.message);
        }
    }, []);

    return (
        <PaymentListPresenter
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            authNumber={authNumber}
            setAuthNumber={setAuthNumber}


            seconds={seconds}
            isAuth={isAuth}

            sendAuthCode={sendAuthCode}
            confirmAuthCode={confirmAuthCode}

            handleLookUp={handleLookUp}

            list={data?.couponPaymentList}
            couponDownload={couponDownload}
        />
    );
};

export default PaymentListContainer;
