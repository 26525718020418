import { SETTLE_SHOP_ID, KAKAO_SHOP_ID } from "./constants";

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const thousandSeparatorComma = number => {
    let string = "" + number;  // 문자로 바꾸기.
    string = string.replace( /[^-+\.\d]/g, "" );  // ±기호와 소수점, 숫자들만 남기고 전부 지우기.
    let regExp = /^([-+]?\d+)(\d{3})(\.\d+)?/;  // 정규식.
    while (regExp.test(string)) {
        string = string.replace( regExp, "$1" + "," + "$2" + "$3" );
    }  // 쉼표 삽입.
    // 콤마 제거 (VALUE) .replace(/\,/g, "")
    return string;
}

// 초 -> 분으로 변환 ex) 180 -> 3:00
export const getTimeString = (seconds) => {
    let minute = '' + Math.floor(seconds / 60);
    let dividedSec = seconds % 60;
    if (dividedSec < 10) {
        dividedSec = '0' + dividedSec;
    }
    return minute + ':' + dividedSec;
}

// 휴대폰 번호 hyphen 제거
export const removeHyphen = phoneNumber => {
    return phoneNumber.replace(/-/g, "");
}

export const pgShopIdChanger = pgCompany => {
    switch (pgCompany) {
        case '신용카드':
            return `settle.${SETTLE_SHOP_ID}`;
        case '카카오페이':
            return `kakaopay.${KAKAO_SHOP_ID}`;
        default:
            return pgCompany;
    }
}

export const payMethodType = payMethod => {
    switch (payMethod) {
        case 'card':
            return '카드결제';
        case 'kakaopay':
            return '카카오페이';
        case 'naverpay':
            return '네이버페이';
        default:
            return payMethod;
    }
 }

 export const pgPayMethodChanger = method => {
     switch (method) {
         case '신용카드':
             return 'card';
         case '카카오페이':
             return 'kakaopay';
         case '네이버페이':
             return 'naverpay';
     }
 }

 export const toastNewLine = text => {
     let str = text.split('.');

     return (
         <div>
             {str[0]}.<br />
             {str[1]}
         </div>
     )
 }