const registerDirectCallListeners = (call, dispatchUpdate) => {
    call.onEstablished = call => {
        dispatchUpdate({callState: 'established'});
    };
    call.onConnected = call => {
        dispatchUpdate({callState: 'connected'});
    };
    call.onReconnected = call => {
        dispatchUpdate({callState: 'reconnected'});
    };
    call.onReconnecting = call => {
        dispatchUpdate({callState: 'reconnecting'});
    };
    call.onEnded = call => {
        dispatchUpdate({callState: 'ended'});
    };
    call.onRemoteAudioSettingsChanged = call => {
        dispatchUpdate({isRemoteAudioEnabled: call.isRemoteAudioEnabled});
    };
    call.onRemoteVideoSettingsChanged = call => {
        dispatchUpdate({isRemoteVideoEnabled: call.isRemoteVideoEnabled});
    };
};

export const statefyDirectCall = (call, dispatch, registerListener) => {
    const dispatchUpdate = part => {
        const payload = {
            callId: call.callId,
            ...part,
        };
        dispatch({type: 'UPDATE_CALL', payload});
    };

    if (registerListener) {
        registerDirectCallListeners(call, dispatchUpdate);
    }

    return {
        callState: (call.localUser.userId === call.caller.userId) ? "dialing" : "ringing",
        callId: call.callId,
        caller: call.caller, // This should not mutate
        callee: call.callee, // This should not mutate
        localUser: call.localUser, // This should not mutate
        remoteUser: call.remoteUser, // This should not mutate
        isLocalAudioEnabled: call.isLocalAudioEnabled,
        isRemoteAudioEnabled: call.isRemoteAudioEnabled,
        myRole: call.myRole,
        isOngoing: call.isOngoing,
        endedBy: call.endedBy, // This should not mutate
        isEnded: call.isEnded,
        endResult: call.endResult,
        // callLog: call.callLog, // This should not mutate
        // customItems: call.customItems, // This should not mutate
        localMediaView: call.localMediaView,
        remoteMediaView: call.remoteMediaView,

        setLocalMediaView(mediaView) {
            dispatchUpdate({localMediaView: mediaView});
            return call.setLocalMediaView(mediaView);
        },
        setRemoteMediaView(mediaView) {
            dispatchUpdate({remoteMediaView: mediaView});
            return call.setRemoteMediaView(mediaView);
        },

        getDuration() {
            return call.getDuration();
        },
        accept(params) {
            return call.accept(params);
        },
        end() {
            return call.end();
        },

        muteMicrophone() {
            dispatchUpdate({isLocalAudioEnabled: false});
            return call.muteMicrophone();
        },
        unmuteMicrophone() {
            dispatchUpdate({isLocalAudioEnabled: true});
            return call.unmuteMicrophone();
        },

    };
};