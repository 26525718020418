import {useCallback, useEffect, useMemo, useReducer, useRef} from "react";
import SendbirdCall, {LoggerLevel, SoundType} from "sendbird-calls";
import initialState from "./state";
import reducer from "./reducer";
import {APP_ID, SB_ID} from "./constants";
import {statefyDirectCall} from './statefy';
import CallContext, {initialContext} from "./context";

const SbCallsProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {calls} = state;
    const currentCall = useMemo(() => calls.find(call => !call.isEnded), [calls]);
    const isBusy = useMemo(() => calls.some(call => !call.isEnded), [calls]);

    const init = useCallback(async () => {
        const listenerId = "qrcall-listener";
        try {
            SendbirdCall.removeListener(listenerId);
        } catch (error) {
            console.info(error);
        }
        // AskBrowserPermission();
        SendbirdCall.init(APP_ID);
        SendbirdCall.setLoggerLevel(LoggerLevel.ERROR);
        SendbirdCall.addListener(listenerId, {
            onRinging: (call) => {},
            onAudioInputDeviceChanged: (current, available) => {
                const wannaUseMicInfo = available.find((avail) => avail.label === 'Headset earpiece');

                if (wannaUseMicInfo) {
                    console.log("wannaUseMicInfo :", wannaUseMicInfo);
                }

                dispatch({
                    type: 'UPDATE_AUDIO_INPUT_DEVICE_INFO',
                    payload: {
                        current,
                        available
                    }
                });

            },

            onAudioOutputDeviceChanged: (current, available) => {
                dispatch({
                    type: 'UPDATE_AUDIO_OUTPUT_DEVICE_INFO',
                    payload: {
                        current,
                        available
                    }
                });
            },
        });
        SendbirdCall.updateMediaDevices({ audio: true, video: false });
    }, []);

    const auth = useCallback(async authOption => {
        const user = await SendbirdCall.authenticate(authOption);

        SendbirdCall.addListener(SB_ID, {
            onRinging: call => {
                dispatch({type: "RINGING", payload: statefyDirectCall(call, dispatch, true)});
            },
            onAudioInputDeviceChanged: (current, available) => {},
            onAudioOutputDeviceChanged: (current, available) => {},
            onVideoInputDeviceChanged: (current, available) => {},
        });
        await SendbirdCall.connectWebSocket();

        dispatch({type: "AUTH", payload: user});
        return user;
    }, []);

    useEffect(() => {
        init();
    }, []);

    const deAuth = useCallback(() => {
        SendbirdCall.removeListener(APP_ID);
        SendbirdCall.deauthenticate();
        dispatch({type: "DE_AUTH"});
    }, []);

    // Media Device Control
    const updateMediaDevices = useCallback(constraints => {
        SendbirdCall.updateMediaDevices(constraints);
    }, []);

    const selectAudioInputDevice = useCallback(mediaInfo => {
        SendbirdCall.selectAudioInputDevice(mediaInfo);
        dispatch({type: "UPDATE_AUDIO_INPUT_DEVICE_INFO", payload: {current: mediaInfo}});
    }, []);

    const selectAudioOutputDevice = useCallback(mediaInfo => {
        SendbirdCall.selectAudioOutputDevice(mediaInfo);
        dispatch({type: "UPDATE_AUDIO_OUTPUT_DEVICE_INFO", payload: {current: mediaInfo}});
    }, []);

    // Direct Calls
    const dial = useCallback(params => new Promise((res, rej) => {
        SendbirdCall.dial(params, (call, error) => {
            const statefulCall = statefyDirectCall(call, dispatch, true);

            if (error) {
                rej(error);
                return;
            }

            dispatch({type: "ADD_CALL", payload: statefulCall});
            res(statefulCall);
        });
    }), []);

    const clearCalls = useCallback(() => dispatch({type: "CLEAR_CALLS"}), []);

    const callContext = {
        ...initialContext,
        ...state,
        init,
        auth,
        deAuth,
        isAuthenticated: !!state.user,

        // Media Device Control
        updateMediaDevices,
        selectAudioInputDevice,
        selectAudioOutputDevice,

        // Direct Calls
        currentCall,
        isBusy,
        dial,
        addDirectCallSound: SendbirdCall.addDirectCallSound,
        clearCalls,
    };

    return (
        <CallContext.Provider value={callContext}>
            {children}
        </CallContext.Provider>
    );
}

export default SbCallsProvider;
