import React, {useCallback, useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import colors from "../../styles/colors";
import {DirectCallContext} from "../../pages/DirectCall/DirectCallContainer";
import useSbCalls from "../../context/useSbCalls";
import CallEndButton from "../Share/CallEndButton";
import {useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {CALL_END} from "../../graphql/calling/mutation";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 106px 20px 60px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: ${colors.whiteColor};
  transition: 0.3s;
  display: none;
  
  ${({ active }) => active && css`
    display: block;
  `}
`;
const Contents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const FlexBox = styled.div`
  flex: 1;
`;
const Text = styled.div`
  font-size: ${({fontSize}) => (fontSize ? fontSize : 14)}px;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 500)};
  margin: ${({margin}) => (margin ? margin : 0)};
  color: ${({fontColor}) => (fontColor ? fontColor : colors.blackColor)};
  text-align: center;
  line-height: 1.5;
`;

const ConnectedView = ({ active, call }) => {
    const navigate = useNavigate();
    const {clearCalls} = useSbCalls();
    const {setPageState, receiverInfo} = useContext(DirectCallContext);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const startTime = new Date();

    const [callEnd] = useMutation(CALL_END);

    useEffect(() => {
        if (active) {
            const timer = setTimeout(() => {
                if (sec < 59) setSec((sec) => sec + 1);
                if (sec === 59) {
                    setSec(0);
                    setMin((min) => min + 1);
                }
                if (min === 59 && sec === 59) {
                    setMin(0);
                    setHour((hour) => hour + 1);
                }
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [active, hour, min, sec]);

    const handleCallEnd = useCallback(async () => { // 자신이 전화를 종료한 경우
        try {
            const { data } = await callEnd({
                variables: {
                    ch_createdAt: startTime,
                    callTime: hour * 3600 + min * 60 + sec,
                    receiver_user_id: receiverInfo.receiver_user_id,
                    qr_id: receiverInfo.qr_id,
                    receverSBId: receiverInfo.receiverSBId,
                    callerSBId: receiverInfo.callerSBId,
                },
            });
            if (data.callEnd.result) {
                clearCalls();
                setPageState("main");
                navigate("/");
            }
        } catch(e) {
            alert(e.message);
            console.info(e.message);
        }
    }, [call, receiverInfo, startTime, hour, min, sec]);

    useEffect(() => {
        if (call.callState === "ended") { // 전화 종료 후 뮤테이션 호출
            handleCallEnd();
        }
    }, [call, receiverInfo, startTime, hour, min, sec]);

    return (
        <Wrapper active={active}>
            <Contents>
                <FlexBox>
                    <Text fontSize={20} fontWeight={700} margin="0 0 14px">
                        {receiverInfo?.qrCardName} 차주와 통화 중
                    </Text>
                    <Text>
                        {hour !== 0 && `${hour} : `}
                        {hour !== 0 ? (min < 10 ? `0${min}` : min) : min} :{" "}
                        {sec < 10 ? `0${sec}` : sec}
                    </Text>
                </FlexBox>
                <FlexBox>
                    <Text>
                        안심하세요,
                        <br/>
                        상대방은 전화번호를 알 수 없습니다.
                    </Text>
                </FlexBox>

                <CallEndButton onClick={() => call.end()}/>
            </Contents>
        </Wrapper>
    );
};

export default React.memo(ConnectedView);
