import initialState from "./state";
import {castDraft, produce} from 'immer';

export default (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH':
            return produce(state, draft => {
                draft.user = action.payload;
            });
        case 'DE_AUTH':
            return initialState;
        case 'ADD_CALL':
            return produce(state, draft => {
                draft.calls.push(castDraft(action.payload));
            });
        case 'RINGING': {
            const isBusy = state.calls.some(call => !call.isEnded);
            const call = action.payload;
            if (isBusy) {
                call.end();
            }
            return produce(state, draft => {
                draft.calls.push(castDraft(call));
            });
        }
        case 'UPDATE_CALL':
            return produce(state, draft => {
                const index = draft.calls.findIndex(call => call.callId === action.payload.callId);
                if (index === -1) return;
                Object.assign(draft.calls[index], castDraft(action.payload));
            });
        case 'CLEAR_CALLS':
            return produce(state, draft => {
                draft.calls = castDraft(initialState.calls);
            });
        case 'UPDATE_AUDIO_INPUT_DEVICE_INFO':
            return produce(state, draft => {
                draft.audioInputDeviceInfo = {...state.audioInputDeviceInfo, ...action.payload};
            });
        case 'UPDATE_AUDIO_OUTPUT_DEVICE_INFO':
            return produce(state, draft => {
                draft.audioOutputDeviceInfo = {...state.audioOutputDeviceInfo, ...action.payload};
            });
        default:
            return state;
    }
}