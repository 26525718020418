import {gql} from '@apollo/client';

export const COUPON_PAYMENT_LIST = gql`
    query couponPaymentList (
        $cellPhone: String!
    ) {
        couponPaymentList (
            cellPhone: $cellPhone
        ) {
            cb_id
            cb_date
            cb_product_name
            cb_amount
            cb_price
            cb_paymentType
            cb_paymentDetail
        }
    }
`;

export const SEE_PREMIUM_EXTEND_PAYMENT_LIST = gql`
    query seePremiumExtendPaymentList (
        $cellphone: String!
    ) {
        seePremiumExtendPaymentList (
            cellphone: $cellphone
        ) {
            upay_date
            upay_period
            upay_price
            upay_paymentType
            upay_paymentDetail
        }
    }
`;