import React, {useCallback, useContext, useEffect} from "react";
import styled, {css} from "styled-components";
import useSbCalls from "../../context/useSbCalls";
import colors from "../../styles/colors";
import CallAnimation from "./CallAnimation";
import {DirectCallContext} from "../../pages/DirectCall/DirectCallContainer";
import ConnectedView from "./ConnectedView";
import CallEndButton from "../Share/CallEndButton";
import {CallInfoContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {isSafari} from "../../utils/commons";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 40px 20px 20px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: ${colors.whiteColor};
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
`;
const Foreground = styled.div`
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
`;
const AudioInput = styled.audio`
  opacity: 0;
`;
const CallImage = styled.div``;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0;
`;
const Text = styled.div`
  font-size: ${({fontSize}) => fontSize ? fontSize : 14}px;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 500};
  line-height: ${({lineHeight}) => lineHeight ? lineHeight : "14px"};
  color: ${({fontColor}) => fontColor ? fontColor : colors.blackColor};
  margin: ${({margin}) => margin ? margin : 0};
  text-align: center;
`;

const CallView = ({
                      call = {
                          callState: 'connect',
                          setLocalMediaView: () => null,
                          setRemoteMediaView: () => null
                      }
                  }) => {
    const navigate = useNavigate();
    const {soundPause} = useContext(CallInfoContext);
    const {setPageState, receiverInfo, caller} = useContext(DirectCallContext);
    const {clearCalls} = useSbCalls();
    const {callState} = call;

    // Audio Ref 설정
    const localMediaViewRef = useCallback(node => call.setLocalMediaView(node), []);
    const remoteMediaViewRef = useCallback(node => call.setRemoteMediaView(node), []);

    const is = useCallback((...states) => states.some(state => state === callState), [callState]);
    const isNot = useCallback((...states) => !states.some(state => state === callState), [callState]);

    const handleCallEnd = useCallback(() => { // 전화 거절
        setPageState("main");
        navigate("/");
        soundPause();
        call.end();
        clearCalls();
    }, [call]);


    useEffect(() => {
        (async () => {
            if (caller && callState !== 'dialing') {
                soundPause();
            }
            if (callState === "connected") {
                setPageState("main");
                soundPause();
            }
            if (callState === "ended") {
                soundPause();
                setPageState("main");
                clearCalls();
                localStorage.removeItem('qrCode');
            }
        })();
        return () => soundPause();
    }, [callState, caller]);

    return (
        <Wrapper>
            <Background>
                <AudioInput
                    ref={localMediaViewRef}
                    playsInline
                    autoPlay
                    muted
                />
                <AudioInput
                    ref={remoteMediaViewRef}
                    playsInline
                    autoPlay
                    muted={false}
                    controls={isSafari()}
                />
            </Background>
            <Foreground>
                <Text fontSize={20} fontWeight={600} margin='20px 0'>전화걸기</Text>
                <Text fontSize={26} fontWeight={600} margin="0 0 20px">
                    {receiverInfo?.qrCardName} 차주
                </Text>

                <CallImage>
                    <CallAnimation/>
                </CallImage>
                <InfoBox>
                    <Text fontSize={26} fontWeight={700} margin="0 0 26px">
                        전화거는중...
                    </Text>
                    <Text lineHeight={1.5}>
                        {!!receiverInfo?.qrCardName ? receiverInfo?.qrCardName : ''} 차주에게 발신 중 입니다.
                        <br/>
                        안심하세요. 전화번호는 노출되지않습니다
                    </Text>
                </InfoBox>
                <CallEndButton onClick={handleCallEnd}/>
            </Foreground>

            <ConnectedView
                call={call}
                active={is("connected")}
            />
        </Wrapper>
    )
}

export default React.memo(CallView);
