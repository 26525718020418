import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from "react";
import DirectCallPresenter from "./DirectCallPresenter";
import useSbCalls from "../../context/useSbCalls";
import {useLocation, useNavigate} from "react-router-dom";

export const DirectCallContext = createContext(null);

const DirectCallContainer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const qrCode = useRef(null);
    const [pageState, setPageState] = useState("main"); // 페이지 상태
    const [messageModal, setMessageModal] = useState(false); // 메세지 모달 상태
    const [caller, setCaller] = useState(false); // 발신자 확인 상태
    const [isBusy, setIsBusy] = useState(false); // 부재중 상태
    const [receiverInfo, setReceiverInfo] = useState(null); // 수신자 정보
    const { calls } = useSbCalls();

    // 현재 전화 상태
    const onCall = useMemo(() => {
        return calls.find(call => call.isOngoing);
    }, [calls])

    // 뒤로가기
    const handleGoBack = useCallback(() => navigate("/"), []);

    // URL 에서 Serial Code Parsing
    useEffect(() => {
        let initialCode = location.pathname.split("/")[2];

        if (initialCode) {
            localStorage.setItem("qrCode", initialCode);
            navigate("/direct-call");
        }
    }, [location]);

    useEffect(() =>{
        if (onCall?.callState === "dialing") {
            setPageState("dialing");
        }
    }, [onCall]);

    useEffect(() => {
        if (localStorage.getItem("qrCode")) {
            qrCode.current = localStorage.getItem("qrCode");
            setTimeout(() => localStorage.removeItem('qrCode'), 1000);
        }
    }, []);

    React.useEffect(() => {
        console.info('시리얼 코드 : ', qrCode.current);
    }, [qrCode]);

    return (
        <DirectCallContext.Provider value={{
            navigate,
            pageState,
            setPageState,
            messageModal,
            setMessageModal,
            caller,
            setCaller,
            receiverInfo,
            setReceiverInfo,
            qrCode,
            isBusy,
            setIsBusy,
        }}>
            <DirectCallPresenter
                onCall={onCall}
                handleGoBack={handleGoBack}
            />
        </DirectCallContext.Provider>
    )
}

export default DirectCallContainer;
