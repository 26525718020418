import React, {useCallback, useEffect, useState} from "react";
import ExtendPPresenter from "./ExtendPPresenter";
import {useMutation} from "@apollo/client";
import {
    CREATE_PREMIUM_EXTEND_PAYMENT,
    PAYMENT_CONFIRM_AUTH_CODE,
    PAYMENT_REQUEST_AUTH_CODE, PREMIUM_AUTH_REQUEST
} from "../../graphql/payment/mutation";
import {toast} from "react-toastify";
import {removeHyphen, pgPayMethodChanger, pgShopIdChanger} from "../../utils/commons";
import { IMP_USER_CODE } from "../../utils/constants";

const {IMP} = window;
IMP.init(IMP_USER_CODE);

const ExtendPContainer = () => {
    const [extendPeriod, setExtendPeriod] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authNumber, setAuthNumber] = useState('');
    const [payMethod, setPayMethod] = useState('신용카드');
    const [seconds, setSeconds] = useState(null);
    const [isAuth, setIsAuth] = useState(false);

    const [premiumAuthRequest] = useMutation(PREMIUM_AUTH_REQUEST);
    const [paymentConfirmAuthCode] = useMutation(PAYMENT_CONFIRM_AUTH_CODE);
    const [createPremiumExtendPayment] = useMutation(CREATE_PREMIUM_EXTEND_PAYMENT);

    // 휴대폰 인증 유효시간 count
    useEffect(() => {
        if (seconds >= 0 && !isAuth) {
            const timer = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(number => number - 1);
                } else if (isAuth) {
                    setSeconds(null);
                    clearInterval(timer);
                } else if (seconds === 0) {
                    alert('인증번호 유효시간이 초과하였습니다. 인증번호를 다시 전송해주세요.');
                    setSeconds(null);
                    clearInterval(timer);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds, isAuth]);

    const calcAmount = (props) => {
        switch (props) {
            case 1:
                return 550;
            case 3:
                return 1650;
            case 6:
                return 3000;
            case 12:
                return 5900;
            default:
                return 550;
        }
    };

    const sendAuthCode = useCallback(async () => {
        if (phoneNumber === '') {
            toast.error("휴대폰 번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.info("인증을 완료했습니다.");
            return;
        }
        // if (!!seconds) return;
        try {
            const {data} = await premiumAuthRequest({
                variables: {
                    cellphone: removeHyphen(phoneNumber)
                }
            });

            console.info('premiumAuthRequest : ', data);

            if (data.premiumAuthRequest) {
                setSeconds(180);
                toast.info("인증번호 6자리를 입력해주세요.");
            }
        } catch (e) {
            console.info(e);
            toast.error(e.message);
        }
    }, [phoneNumber, seconds, isAuth]);

    const confirmAuthCode = useCallback(async () => {
        if (authNumber === '') {
            toast.error("인증번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.info("인증을 완료했습니다.");
            return;
        }
        if (seconds > 0 && !isAuth) {
            try {
                const {data} = await paymentConfirmAuthCode({
                    variables: {
                        cellphone: removeHyphen(phoneNumber),
                        authCode: authNumber
                    }
                });

                if (data.paymentConfirmAuthCode) {
                    toast.info('인증이 완료되었습니다.');
                    setSeconds(null);
                    setIsAuth(true);
                }

            } catch (e) {
                toast.error(e.message);
                console.info(e.message)
            }
        }
    }, [authNumber, seconds, phoneNumber, isAuth]);

    const onClickPayment = useCallback(() => {
        if (!isAuth) {
            toast.info('휴대폰 인증이 완료되지 않았습니다.');
            return;
        }

        const data = {
            pg: pgShopIdChanger(payMethod),
            pay_method: pgPayMethodChanger(payMethod),
            merchant_uid: `mid_${new Date().getTime()}`,
            name: `프리미엄 연장-${extendPeriod}개월`,
            amount: calcAmount(extendPeriod),
            company: '시큐릿',
            buyer_name: '',
            buyer_tel: phoneNumber,
            buyer_email: '',
            buyer_addr: '',
            buyer_postalcode: '',
        };

        IMP.request_pay(data, callback);
    }, [isAuth, extendPeriod, payMethod, phoneNumber]);

    const callback = useCallback(async response => {
        const {success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status} = response;

        try {
            if (success) {
                const { data } = await createPremiumExtendPayment({
                    variables: {
                        cellphone: removeHyphen(phoneNumber),
                        upayDate: new Date(),
                        upayPeriod: extendPeriod,
                        upayPrice: paid_amount,
                        upayPaymentType: pay_method,
                        upayPaymentDetail: pay_method === 'card' ? response.card_name : '',
                        upayPaySuccess: success,
                    }
                });
                if (success && data.createPremiumExtendPayment) {
                    toast.info('결제에 성공했습니다.');
                    setAuthNumber('');
                    setPhoneNumber('');
                    setExtendPeriod(1);
                }
            } else {
                toast.error(`결제에 실패했습니다.\n${error_msg}`);
            }
        } catch (e) {
            toast.error(e.message);
            console.info(e.message);
        }
    }, [phoneNumber, extendPeriod]);


    return (
        <ExtendPPresenter
            seconds={seconds}
            isAuth={isAuth}
            authNumber={authNumber}
            setAuthNumber={setAuthNumber}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            extendPeriod={extendPeriod}
            setExtendPeriod={setExtendPeriod}
            calcAmount={calcAmount}
            onClickPayment={onClickPayment}
            payMethod={payMethod}
            setPayMethod={setPayMethod}


            sendAuthCode={sendAuthCode}
            confirmAuthCode={confirmAuthCode}
        />
    );
};

export default ExtendPContainer;
