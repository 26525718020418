import React from "react";
import styled from "styled-components";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import colors from "../../styles/colors";
import {IoArrowDownCircleOutline} from "react-icons/io5";
import {getTimeString, payMethodType} from "../../utils/commons";

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const ContentsBox = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 100px;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px) {
    padding: 0px 12px;
  }
`;
const Title = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 12px;
  font-size: 22px;
  margin-bottom: 24px;
`;
const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 0px;
  align-items: center;
  height: 58px;
  border: 1px solid ${colors.lightGrayColor};
  @media only screen and (max-width: 768px) {
    padding: 0px 12px;
  }
`;
const LineTitle = styled.span`
  width: 220px;
  font-weight: bold;
  margin-left: 12px;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;
const LineInput = styled.input`
  width: 180px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${colors.grayFontColor};
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;

const AuthButton = styled.div`
  margin-left: 12px;
  height: 36px;
  border-radius: 4px;
  background-color: ${colors.blackColor};
  width: 44px;
  color: ${colors.whiteColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }
`;
const PaymentBox = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 0px;
  border: 1px solid ${colors.lightGrayColor};
`;
const PaymentListWrapper = styled.div``;
const PaymentContents = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border: 0px solid ${colors.lightGrayColor};
`;
const PaymentLineTitle = styled.div`
  color: ${colors.grayFontColor};
`;
const PaymentLineValue = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;
const PaymentDown = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
`;

const PayButton = styled.div`
  border-radius: 8px;
  height: 44px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blackColor};
  color: ${colors.whiteColor};
  margin-right: 20px;
  font-size: 18px;
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const TimerText = styled.span`
  color: ${colors.redColor};
  margin-left: 20px;
`;

const PaymentListPresenter = ({
                                  phoneNumber,
                                  setPhoneNumber,
                                  authNumber,
                                  setAuthNumber,

                                  seconds,
                                  isAuth,

                                  sendAuthCode,
                                  confirmAuthCode,

                                  handleLookUp,

                                  list,
                                  couponDownload,
                              }) => {

    return (
        <>
            <Header/>
            <Wrapper>
                <ContentsBox>
                    <Title>조회자 정보</Title>
                    <LineWrapper>
                        <LineTitle>휴대폰번호</LineTitle>
                        <LineInput
                            value={phoneNumber}
                            onChange={(e) =>
                                setPhoneNumber(
                                    e.target.value
                                        .replace(/[^0-9]/g, "")
                                        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                                        .replace(/(\-{1,2})$/g, "")
                                )
                            }
                        />
                        <AuthButton onClick={sendAuthCode}>전송</AuthButton>
                    </LineWrapper>
                    <LineWrapper>
                        <LineTitle>인증번호 입력</LineTitle>
                        <LineInput
                            value={authNumber}
                            onChange={(e) => setAuthNumber(e.target.value)}
                            type="number"
                            maxlength="6"
                        />
                        <AuthButton onClick={confirmAuthCode}>확인</AuthButton>
                        {(!!seconds && !isAuth) && <TimerText>{getTimeString(seconds)}</TimerText>}
                    </LineWrapper>
                </ContentsBox>
                <ContentsBox>
                    <PayButton onClick={handleLookUp}>
                        조회하기
                    </PayButton>
                </ContentsBox>
                <ContentsBox>
                    <Title>조회내용</Title>
                    {(isAuth && list) &&
                    list?.map(item => (
                        <PaymentBox key={item.cb_id}>
                            <LineTitle>구매내역</LineTitle>
                            <PaymentListWrapper>
                                <PaymentContents>
                                    <PaymentLineTitle>구매일시 : </PaymentLineTitle>
                                    <PaymentLineValue>{new Date(Number(item?.cb_date))?.toLocaleDateString()}</PaymentLineValue>
                                </PaymentContents>
                                <PaymentContents>
                                    <PaymentLineTitle>수량 : </PaymentLineTitle>
                                    <PaymentLineValue>{item?.cb_amount}</PaymentLineValue>
                                </PaymentContents>
                                <PaymentContents>
                                    <PaymentLineTitle>금액 : </PaymentLineTitle>
                                    <PaymentLineValue>{item?.cb_price.toLocaleString()} 원</PaymentLineValue>
                                </PaymentContents>
                                <PaymentContents>
                                    <PaymentLineTitle>결제방법 : </PaymentLineTitle>
                                    <PaymentLineValue>{payMethodType(item?.cb_paymentType)}</PaymentLineValue>
                                </PaymentContents>
                                <PaymentContents>
                                    <PaymentLineTitle>쿠폰 다운로드 : </PaymentLineTitle>
                                    <PaymentDown onClick={() => couponDownload(item.cb_id)}>
                                        <IoArrowDownCircleOutline
                                            style={{marginRight: 8}}
                                            size={22}
                                        />
                                        다운
                                    </PaymentDown>
                                </PaymentContents>
                            </PaymentListWrapper>
                        </PaymentBox>
                    ))}
                </ContentsBox>
                <Footer/>
            </Wrapper>
        </>
    );
};

export default PaymentListPresenter;
