import React, {useCallback, useContext, useEffect, useState} from "react";
import styled, { css } from "styled-components";
import messageTitleIcon from "../../assets/icons/messageTitleIcon.svg";
import closeIcon from "../../assets/icons/closeIcon.svg";
import colors from "../../styles/colors";
import {DirectCallContext} from "../../pages/DirectCall/DirectCallContainer";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {SEND_CALL_MESSAGE} from "../../graphql/calling/mutation";
import {GET_RECEIVER_INFO} from "../../graphql/calling/query";

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: -100%;
  margin: 0 auto;
  background-color: ${colors.whiteColor};
  transition: .3s;

  ${({ active }) => active && css`
    bottom: 0;
  `};
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const ModalClose = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  flex: 1;
`;
const Image = styled.img`
  cursor: pointer;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  text-align: center;
`;
const FlexBox = styled.div`
  flex: 1;
  display: flex;
  align-items: ${({ alignItems }) => alignItems ? alignItems : "flex-start"};
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : "flex-start"};
`;
const MessageTitle = styled.div`
  padding: 0 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 12px;
  border-bottom: 1px solid ${colors.lightGrayColor};
`;
const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize ? fontSize : 14}px;
  font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 500};
  color: ${({ fontColor }) => fontColor ? fontColor : colors.blackColor};
  margin: ${({ margin }) => margin ? margin : 0};
`;
const Textarea = styled.textarea`
  width: 100%;
  height: calc(100% - 200px);
  font-size: 14px;
  padding: 12px;
  
  &::placeholder {
    color: ${colors.grayFontColor};
    font-weight: 400;
  }
`;
const SendButton = styled.div`
  width: 100%;
  max-width: 350px;
  height: 52px;
  color: ${colors.whiteColor};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto;
  border-radius: 26px;
  background-color: ${colors.blackColor};
  cursor: pointer;
`;

const MessageModal = () => {
    const [message, setMessage] = useState("");
    const { messageModal, setMessageModal, receiverInfo } = useContext(DirectCallContext);

    const [sendCallMessage] = useMutation(SEND_CALL_MESSAGE);

    const handleSendMessage = useCallback(async () => {
        if (message === "") {
            alert("보내실 메세지를 입력해주세요.");
            return;
        }
        if (!receiverInfo) {
            alert('수신자에 대한 정보가 없습니다.\nQR카드를 스캔해주세요.');
            return;
        }

        try {
            const { data } = await sendCallMessage({
                variables: {
                    receiverUserId: receiverInfo?.receiver_user_id,
                    qrId: receiverInfo?.qr_id,
                    message: message
                }
            });

            if (data.sendCallMessage) {
                alert("메시지를 전송했습니다.");
                setMessageModal(false);
            }
        } catch(e) {
            alert(e.message);
        }
    }, [receiverInfo, message]);

    return (
        <Wrapper active={messageModal}>
            <Header>
                <ModalClose onClick={() => setMessageModal(false)}>
                    <Image src={closeIcon} />
                </ModalClose>
                <Title>메시지보내기</Title>
                <FlexBox />
            </Header>
            <MessageTitle>
                <FlexBox>
                    <Image src={messageTitleIcon} />
                    <Text margin="0 0 0 12px">{receiverInfo?.qrCardName} 차주에게 보내는 메시지</Text>
                </FlexBox>
                <Text>( {message.length} / 400 )</Text>
            </MessageTitle>
            <Textarea
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="원하는 메시지를 입력해주세요."
            />
            <SendButton onClick={handleSendMessage}>전송하기</SendButton>
        </Wrapper>
    )
}

export default MessageModal;
