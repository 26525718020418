import React from "react";
import styled from "styled-components";
import goBackIcon from "../../assets/icons/goBack.svg";

const Button = styled.button`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img``;

const GoBack = ({handleGoBack}) => {
    return (
        <Button onClick={handleGoBack}>
            <Image src={goBackIcon}/>
        </Button>
    )
}

export default React.memo(GoBack);
