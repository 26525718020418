import React from "react";
import styled from "styled-components";
import DialView from "../../components/DirectCall/DialView";
import CallView from "../../components/DirectCall/CallView";
import MainView from "../../components/DirectCall/MainView";
import MessageModal from "../../components/Modal/MessageModal";

const Wrapper = styled.div`
  max-width: 768px;
  height: calc(var(--vh, 1vh) * 100);
  padding: 20px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

const DirectCallPresenter = ({
                                 onCall,
                                 handleGoBack
                             }) => {
    return (
        <Wrapper>
            {/* 전화 메인 화면 */}
            <MainView/>

            {onCall && ( // 수신 전화 화면
                <CallView
                    call={onCall}
                    handleGoBack={handleGoBack}
                />
            )}

            {/* 전화걸기 화면 */}
            <DialView
                call={onCall}
            />

            {/* 메세지 모달 */}
            <MessageModal/>
        </Wrapper>
    )
}

export default React.memo(DirectCallPresenter);
