import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {ApolloProvider} from "@apollo/client";
import Client from "./Apollo/Client";
import SbCallsProvider from "./context/provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ApolloProvider client={Client}>
        <SbCallsProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </SbCallsProvider>
    </ApolloProvider>
);
