import React from "react";
import styled, { keyframes } from "styled-components";
import phoneIcon from "../../assets/icons/phoneIcon.svg";
import colors from "../../styles/colors";

const waveColor = ["#E3E3E7", "#ECEBF2", "#F1F1F3", "#f8f8f8", "#fff"];

const wave_1 = keyframes`
  0% { background-color: ${waveColor[0]} }
  20% { background-color: ${waveColor[1]} }
  40% { background-color: ${waveColor[2]} }
  60% { background-color: ${waveColor[3]} }
  80% { background-color: ${waveColor[4]} }
  100% { background-color: ${waveColor[0]} }
`;
const wave_2 = keyframes`
  0% { background-color: ${waveColor[1]} }
  20% { background-color: ${waveColor[2]} }
  40% { background-color: ${waveColor[3]} }
  60% { background-color: ${waveColor[4]} }
  80% { background-color: ${waveColor[0]} }
  100% { background-color: ${waveColor[1]} }
`;
const wave_3 = keyframes`
  0% { background-color: ${waveColor[2]} }
  20% { background-color: ${waveColor[3]} }
  40% { background-color: ${waveColor[4]} }
  60% { background-color: ${waveColor[0]} }
  80% { background-color: ${waveColor[1]} }
  100% { background-color: ${waveColor[2]} }
`;
const wave_4 = keyframes`
  0% { background-color: ${waveColor[3]} }
  20% { background-color: ${waveColor[4]} }
  40% { background-color: ${waveColor[0]} }
  60% { background-color: ${waveColor[1]} }
  80% { background-color: ${waveColor[2]} }
  100% { background-color: ${waveColor[3]} }
`;

const Wrapper = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
`;
const CallBox = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  z-index: 5;
  border-radius: 50%;
  background: url(${phoneIcon}) no-repeat 50% 50% ${colors.blackColor};
  background-size: 56px;
`;
const Wave = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  position: absolute;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  transition: .3s;
  animation: ${({ animation }) => animation} 3s linear 1s infinite;
  z-index: ${({ zIndex }) => zIndex};
`;

const CallAnimation = () => {
    return (
        <Wrapper>
            <Wave width={150} height={150} animation={wave_4} zIndex={4} bgColor={waveColor[0]} />
            <Wave width={200} height={200} animation={wave_3} zIndex={3} bgColor={waveColor[1]} />
            <Wave width={250} height={250} animation={wave_2} zIndex={2} bgColor={waveColor[2]} />
            <Wave width={300} height={300} animation={wave_1} zIndex={1} bgColor={waveColor[3]} />
            <CallBox />
        </Wrapper>
    )
}

export default CallAnimation;
