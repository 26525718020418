import React, {useCallback, useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import useSbCalls from "../../context/useSbCalls";
import colors from "../../styles/colors";
import callingImage from "../../assets/callingImage/callingImage.svg";
import phoneIcon from "../../assets/callingImage/phoneIcon.svg";
import messageIcon from "../../assets/callingImage/messageIcon.svg";
import GoBack from "../Share/GoBack";
import {DirectCallContext} from "../../pages/DirectCall/DirectCallContainer";
import {useMutation} from "@apollo/client";
import {SEND_CALL, SEND_CALL_MESSAGE} from "../../graphql/calling/mutation";
import {CallInfoContext} from "../../App";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 20px 0;
  position: absolute;
  bottom: -100%;
  left: 0;
  margin: 0 auto;
  background-color: ${colors.whiteColor};
  transition: .3s;

  ${({pageState}) => pageState && css`
    bottom: 0;
  `};
`;
const Container = styled.div`
  height: calc(100% - 38px);
  padding: 10px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${colors.whiteColor};
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Text = styled.div`
  font-size: ${({fontSize}) => fontSize ? fontSize : 14}px;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 500};
  margin: ${({margin}) => margin ? margin : 0};
  color: ${({fontColor}) => fontColor ? fontColor : colors.blackColor};
`;
const Image = styled.img``;
const CallButton = styled.button`
  width: 156px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 30px;
  background-color: ${colors.blackColor};
  flex-shrink: 0;
  
  &:active {
    opacity: 0.6;
  }
`;
const MessageBox = styled.div`
  width: 170px;
  height: 66px;
  position: absolute;
  bottom: 63px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: ${colors.blueColor};
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 13px solid ${colors.blueColor};
  margin-top: 8px;
  margin-left: -4px;
`;
const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;
const MessageButton = styled.button`
  height: 52px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;
const MainImage = styled.img`
  width: 40vw;
  max-width: 160px;
`;

const DialView = ({call}) => {
    const {calls, dial, clearCalls} = useSbCalls();
    const {soundPlay} = useContext(CallInfoContext);
    const {
        pageState,
        setPageState,
        setMessageModal,
        caller,
        setCaller,
        receiverInfo,
        isBusy,
        setIsBusy
    } = useContext(DirectCallContext);
    const [messageView, setMessageView] = useState(true);
    const [connected, setConnected] = useState(false);

    const [sendCall] = useMutation(SEND_CALL);
    // const [sendCallMessage] = useMutation(SEND_CALL_MESSAGE);

    const handleDialCall = useCallback(async () => { // 전화 걸기
        try {
            if (receiverInfo) {
                const {data} = await sendCall({
                    variables: {
                        receiver_user_id: receiverInfo.receiver_user_id,
                        qr_id: receiverInfo.qr_id,
                        receiverSBId: receiverInfo.receiverSBId,
                        callerSBId: receiverInfo.callerSBId,
                    }
                });

                if (data?.sendCall?.result) {
                    soundPlay();
                    dial({
                        userId: receiverInfo.receiverSBId,
                        isVideoCall: false,
                        callOption: {
                            localMediaView: undefined,
                            remoteMediaView: undefined,
                            audioEnabled: true,
                            videoEnabled: false,
                        }
                    });
                    setCaller(true); // 전화 발신 상태
                }
            } else {
                alert('수신자에 대한 정보가 없습니다.\nQR카드를 스캔해주세요.');
            }
        } catch (e) {
            alert(e.message);
            console.info(e.message);
        }
    }, [receiverInfo]);

    // const handleIsBusyMessage = useCallback(async () => { // 부재중 메시지 함수
    //     try {
    //         const {data} = await sendCallMessage({
    //             variables: {
    //                 receiver_user_id: receiverInfo.receiver_user_id,
    //                 qr_id: receiverInfo.qr_id,
    //                 message: `${receiverInfo.qrCardName}에서 부재중 전화가 있습니다.`
    //             }
    //         });
    //
    //         if (data.sendCallMessage) {
    //             alert("상대방에게 부재중 메시지를 보냈습니다.");
    //         }
    //     } catch (e) {
    //         alert(e.message);
    //         console.info(e.message);
    //     }
    // }, [receiverInfo]);

    useEffect(() => { // 전화 상태에 따른 통화 상태 변경
        if (call?.callState === "connected") { // 전화 연결 시
            setPageState("main");
            setConnected(true);
        }
        if (call?.callState === "ended") { // 발신 전화 종료 시 (상대방이 거절 또는 부재중)
            if (!connected) {
                setIsBusy(true);
            }
            setPageState("main");
            clearCalls();
        }
    }, [call?.callState]);

    useEffect(() => { // 안내 메세지 3초간 띄우기
        setTimeout(() => setMessageView(false), 3000);
    }, []);

    useEffect(() => { // 부재중 일 때 메시지 보내기
        if (!connected && caller && isBusy && pageState === "dialing" && call?.callState === "ended") {
            alert("상대방이 전화를 받을 수 없는 상태입니다.\n잠시 후 다시 시도해주십시요.");
            setPageState("main");
            // handleIsBusyMessage();
            // clearCalls();
        }
    }, [connected, caller, pageState, call, isBusy, receiverInfo]);


    return (
        <Wrapper pageState={pageState === "dialing"}>
            <GoBack handleGoBack={() => setPageState("main")}/>

            <Container>
                <TextBox>
                    <Text fontSize={20} fontWeight={600}>전화걸기</Text>
                    <Text fontSize={26} fontWeight={600} margin="10px 0 20px">
                        {receiverInfo?.qrCardName}
                    </Text>
                </TextBox>
                <MainImage src={callingImage}/>

                <ButtonBox>
                    <CallButton onClick={handleDialCall}>
                        <Image src={phoneIcon}/>
                        {messageView && (
                            <MessageBox>
                                <Text
                                    fontSize={14}
                                    fontColor={colors.whiteColor}
                                    fontWeight={600}
                                    margin="0 0 10px"
                                >
                                    통화 버튼을 눌러 전화를
                                </Text>
                                <Text fontSize={14} fontColor={colors.whiteColor} fontWeight="bold">
                                    걸어보세요
                                </Text>
                                <Triangle/>
                            </MessageBox>
                        )}
                    </CallButton>
                    <MessageButton onClick={() => setMessageModal(true)}>
                        <Image src={messageIcon}/>
                        <Text
                            fontWeight={300}
                            color={colors.grayFontColor}
                            margin="0 0 0 10px"
                        >
                            메시지 보내기
                        </Text>
                    </MessageButton>
                </ButtonBox>
            </Container>
        </Wrapper>
    )
}

export default React.memo(DialView);
