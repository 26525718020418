import { createContext } from "react";
import initialState from "./state";

export const initialContext = {
    ...initialState,
    isAuthenticated: false,
    init: () => null,
    auth: () => null,
    deAuth: () => null,

    // Media Device Control
    updateMediaDevices: null,
    selectAudioInputDevice: null,
    selectAudioOutputDevice: null,
    selectVideoInputDevice: null,

    // Direct Calls
    isBusy: false,
    currentCall: undefined,
    dial: () => null,
    addDirectCallSound: () => null,
    clearCalls: () => null
}

const CallContext = createContext(initialContext);

export default CallContext;