import React, {useCallback, useContext, useEffect} from "react";
import styled, {css} from "styled-components";
import colors from "../../styles/colors";
import appIcon from '../../assets/icons/appLogo.svg'
import callingImage from "../../assets/callingImage/callingImage.svg";
import {DirectCallContext} from "../../pages/DirectCall/DirectCallContainer";
import {useLazyQuery} from "@apollo/client";
import {GET_RECEIVER_INFO} from "../../graphql/calling/query";
import useSbCalls from "../../context/useSbCalls";
import {TOKEN} from "../../context/constants";

const Wrapper = styled.div`
  height: 100%;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const LogoBox = styled.div`
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-left: 10px;
  border-radius: 16px;
  box-shadow: 0 3px 12px ${colors.lightGrayColor};
`;
const LogoImage = styled.img`
  width: 84%;
  height: 84%;
`;
const RowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  font-size: ${({fontSize}) => fontSize}px;
  font-weight: ${({fontWeight}) => fontWeight};
  margin: ${({margin}) => margin};
  line-height: ${({TITLE}) => TITLE && 25}px;
  cursor: ${({CURSOR}) => CURSOR && "pointer"};
  text-align: center;
`;
const ButtonBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Button = styled.button`
  width: 100%;
  max-width: 350px;
  height: 52px;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.lightGrayFontColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background-color: ${colors.whiteColor};

  & + & {
    margin-top: 14px;
  }

  ${({filled}) => filled && css`
    color: ${colors.whiteColor};
    background-color: ${colors.blackColor};
  `};
`;
const MainImage = styled.img`
  width: 40vw;
  max-width: 160px;
  //max-width: 768px;
`;

const MainView = () => {
    const {auth} = useSbCalls();
    const {navigate, setPageState, setMessageModal, setReceiverInfo, qrCode} = useContext(DirectCallContext);

    const [getReceiverInfo, {data: receiverData, loading: receiverLoading, error}] = useLazyQuery(GET_RECEIVER_INFO);

    const handleDialReady = useCallback(async () => { // 전화하기 누를 시 수신자 정보 받아오기
        try {
            await getReceiverInfo({
                variables: {
                    qrSerial: qrCode.current,
                }
            });
            setPageState("dialing");
        } catch (e) {
            alert(e.message);
            // console.info(e.message);
        }
    }, [qrCode, getReceiverInfo]);

    const sendMessage = useCallback(async () => {
        setMessageModal(true);
        try {
            await getReceiverInfo({
                variables: {
                    qrSerial: qrCode.current,
                }
            });
        } catch (e) {
            alert(e.message);
        }
    }, [qrCode, getReceiverInfo]);

    useEffect(() => {
        if (!receiverLoading && receiverData?.getReceiverInfo) { // 수신자 정보가 있을 경우
            setReceiverInfo(receiverData?.getReceiverInfo);
            auth({ // Sendbird Init
                userId: receiverData?.getReceiverInfo.callerSBId,
                accessToken: TOKEN
            });
        }
    }, [receiverData, receiverLoading]);

    useEffect(() => {
        if (error) {
            alert(error.message);
            navigate("/");
        }
    }, [error]);

    return (
        <Wrapper>
            <RowBox>
                <LogoBox>
                    <LogoImage src={appIcon}/>
                </LogoBox>
                <Text fontSize={20} fontWeight={600} TITLE>
                    번호가 보이지 않는 QR 카드 <br/> 안심 전화 솔루션, 시크릿
                </Text>
            </RowBox>
            <ColumnBox>
                <MainImage src={callingImage}/>
                <Text fontSize={14} CURSOR margin="10px 0 0" onClick={() => window.open("https://www.seqret.co.kr/")}>
                    앱 보러가기
                </Text>
            </ColumnBox>
            <ButtonBox>
                <Button filled onClick={handleDialReady}>전화하기</Button>
                <Button onClick={sendMessage}>메세지 보내기</Button>
            </ButtonBox>
        </Wrapper>
    )
}

export default MainView;
