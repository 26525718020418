import React, {useCallback, useEffect, useState} from "react";
import BuyCouponPresenter from "./BuyCouponPresenter";
import {useNavigate} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {
    CREATE_PURCHASE_COUPON,
    PAYMENT_CONFIRM_AUTH_CODE,
    PAYMENT_REQUEST_AUTH_CODE
} from "../../graphql/payment/mutation";
import {toast} from "react-toastify";
import {pgPayMethodChanger, pgShopIdChanger, removeHyphen, toastNewLine} from "../../utils/commons";
import { IMP_USER_CODE } from "../../utils/constants";

const {IMP} = window;
IMP.init(IMP_USER_CODE);

const BuyCouponContainer = () => {
    const navigate = useNavigate();
    const [couponType, setCouponType] = useState(1);
    const [couponNumber, setCouponNumber] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [authNumber, setAuthNumber] = useState('');
    const [payMethod, setPayMethod] = useState('신용카드');
    const [seconds, setSeconds] = useState(null);
    const [isAuth, setIsAuth] = useState(false);

    const [paymentRequestAuthCode] = useMutation(PAYMENT_REQUEST_AUTH_CODE);
    const [paymentConfirmAuthCode] = useMutation(PAYMENT_CONFIRM_AUTH_CODE);
    const [createPurchaseCoupon] = useMutation(CREATE_PURCHASE_COUPON);

    // 휴대폰 인증 유효시간 count
    useEffect(() => {
        if (seconds >= 0 && !isAuth) {
            const timer = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(number => number - 1);
                } else if (isAuth) {
                    setSeconds(null);
                    clearInterval(timer);
                } else if (seconds === 0) {
                    toast.error('인증번호 유효시간이 초과하였습니다. 인증번호를 다시 전송해주세요.');
                    setSeconds(null);
                    clearInterval(timer);
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds, isAuth]);

    const calcAmount = (props) => {
        switch (props) {
            case 1:
                return 550;
            case 3:
                return 1650;
            case 6:
                return 3000;
            case 12:
                return 5900;
            default:
                return 550;
        }
    };

    const sendAuthCode = useCallback(async () => {
        if (phoneNumber === '') {
            toast.error("휴대폰 번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.info("인증을 완료했습니다.");
            return;
        }
        // if (!!seconds) return;
        try {
            const {data} = await paymentRequestAuthCode({
                variables: {
                    cellphone: phoneNumber
                }
            });

            console.info('paymentRequestAuthCode : ', data);
            if (data.paymentRequestAuthCode) {
                toast.info("인증번호 6자리를 입력해주세요.");
                setSeconds(180);
            }
        } catch (e) {
            console.info(e);
            toast.error(toastNewLine(e.message));
        }
    }, [phoneNumber, seconds, isAuth]);

    const confirmAuthCode = useCallback(async () => {
        if (authNumber === '') {
            toast.error("인증번호를 입력해주세요");
            return;
        }
        if (isAuth) {
            toast.error("인증을 완료했습니다.");
            return;
        }
        if (seconds > 0 && !isAuth) {
            try {
                const {data} = await paymentConfirmAuthCode({
                    variables: {
                        cellphone: phoneNumber,
                        authCode: authNumber
                    }
                });

                if (data.paymentConfirmAuthCode) {
                    toast.info('인증이 완료되었습니다.');
                    setSeconds(null);
                    setIsAuth(true);
                }

            } catch (e) {
                toast.error(e.message);
                console.info(e.message)
            }
        }
    }, [authNumber, seconds, phoneNumber, isAuth]);

    React.useEffect(() => {
        console.info('payMethod', payMethod);
    }, [payMethod]);

    const onClickPayment = useCallback(async () => {
        if (!isAuth) {
            toast.error('휴대폰 인증이 완료되지 않았습니다.');
            return;
        }

        const data = {
            pg: pgShopIdChanger(payMethod),
            pay_method: pgPayMethodChanger(payMethod),
            merchant_uid: `mid_${new Date().getTime()}`,
            name: `쿠폰구매-${couponType}개월-${couponNumber}개`,
            amount: calcAmount(couponType) * couponNumber,
            company: '시큐릿',
            buyer_name: '',
            buyer_tel: phoneNumber,
            buyer_email: '',
            buyer_addr: '',
            buyer_postalcode: '',
        };

        IMP.request_pay(data, callback);
    }, [isAuth, couponType, couponNumber, payMethod, phoneNumber]);

  const callback = useCallback(
    async (response) => {
      const {
        success,
        error_msg,
        imp_uid,
        merchant_uid,
        pay_method,
        paid_amount,
        status,
      } = response;

        try {
            if (success) {
                const {data} = await createPurchaseCoupon({
                    variables: {
                        cbDate: new Date(),
                        cbCellPhone: removeHyphen(phoneNumber),
                        cbPrice: Number(paid_amount),
                        cbPaymentType: pay_method,
                        cbPaymentDetail: pay_method === 'card' ? response.card_name : '',
                        cbAmount: Number(couponNumber),
                        cbPeriod: Number(couponType),
                        cbPaySuccess: success,
                    }
                });

                if (success && data.createPurchaseCoupon) {
                    toast.info('결제에 성공했습니다.');
                    setCouponType(1);
                    setCouponNumber(1);
                    setAuthNumber('');
                    setPhoneNumber('');
                }
            } else {
                toast.error(`결제에 실패했습니다.`);
                console.info(`결제에 실패했습니다.\n${error_msg}`)
            }
        } catch (e) {
            toast.error(e.message);
            console.info(e.message);
        }
    }, [phoneNumber, couponType, couponNumber]);

    return (
        <BuyCouponPresenter
            seconds={seconds}
            isAuth={isAuth}
            authNumber={authNumber}
            setAuthNumber={setAuthNumber}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            couponNumber={couponNumber}
            setCouponNumber={setCouponNumber}
            couponType={couponType}
            setCouponType={setCouponType}
            calcAmount={calcAmount}
            onClickPayment={onClickPayment}
            payMethod={payMethod}
            setPayMethod={setPayMethod}


            sendAuthCode={sendAuthCode}
            confirmAuthCode={confirmAuthCode}
        />
    );
};

export default BuyCouponContainer;
