import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import {Link} from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  padding: 54px 100px 146px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px soild ${colors.borderGrayColor};
  flex-wrap: wrap;
  transition: .6s;

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 1024px) {
    height: 600px;
    font-size: 18px;
    padding: 0 36px 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: nowrap;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    font-size: 16px;
    padding: 0 24px 50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: nowrap;
  }
  @media only screen and (max-width: 420px) {
    font-size: 14px;
    padding: 0 16px 20px;
    flex-wrap: nowrap;
  }
`;

const LineWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    height: 600px;
    padding: 0 32px 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  @media only screen and (max-width: 768px) {
    height: 220px;
    padding: 0 16px 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  @media only screen and (max-width: 420px) {
    padding: 0 0 80px;
  }
`;
const VerticalBar = styled.div`
  width: 1px;
  height: 22px;
  background-color: ${colors.grayFontColor};
  margin: 0px 12px;
  @media only screen and (max-width: 1024px) {
    width: 0px;
    height: 0px;
  }
`;

const BoldText = styled.span`
  font-weight: 800;
  line-height: 27px;
  margin-bottom: 12px;
`;
const MediumText = styled.span`
  cursor: pointer;
  line-height: 27px;

  &:hover {
    color: ${colors.blueColor};
  }
`;
const Text = styled.span`
  line-height: 27px;
`;

const Footer = () => {
    return (
        <Wrapper>
            <BoldText>주식회사 시큐릿</BoldText>
            <LineWrapper>
                <Text>사업자등록번호 : 652-88-02258</Text>
                <VerticalBar/>
                <Text>사무실번호 : 1644-8318</Text>
                <VerticalBar/>
                <Text>통신판매업신고번호 : 제 2023-경기오산-0446 호</Text>
            </LineWrapper>
            <LineWrapper>
                <Text>대표자명 : 안요한</Text>
                <VerticalBar/>
                <Text>대표 이메일 : lopda_holdings@naver.com</Text>
                <VerticalBar/>
                <Text>사업장 주소 : 경기도 오산시 원동로37번길 17</Text>
            </LineWrapper>
            <LineWrapper>
                <MediumText
                    onClick={() => window.open("https://agreements.seqret.co.kr/service", "_blank")}
                >
                    이용약관
                </MediumText>
                <VerticalBar/>
                <MediumText
                    onClick={() => window.open("https://agreements.seqret.co.kr/policy", "_blank")}
                >
                    개인정보처리방침
                </MediumText>
                <VerticalBar/>
                <Link to="/buyCoupon">
                    <MediumText>쿠폰구매</MediumText>
                </Link>
                <VerticalBar/>
                <Link to="/extendPeriod">
                    <MediumText>프리미엄연장</MediumText>
                </Link>
            </LineWrapper>
        </Wrapper>
    );
};

export default React.memo(Footer);
