import { gql } from '@apollo/client';

export const PAYMENT_REQUEST_AUTH_CODE = gql`
    mutation paymentRequestAuthCode (
        $cellphone: String
    ) {
        paymentRequestAuthCode (
            cellphone: $cellphone
        )
    }
`;

export const PAYMENT_CONFIRM_AUTH_CODE = gql`
    mutation paymentConfirmAuthCode (
        $cellphone: String
        $authCode: String
    ) {
        paymentConfirmAuthCode (
            cellphone: $cellphone
            authCode: $authCode
        )
    }
`;

export const CREATE_PURCHASE_COUPON = gql`
    mutation createPurchaseCoupon (
        $cbDate: String!
        $cbCellPhone: String!
        $cbPrice: Int!
        $cbPaymentType: String!
        $cbPaymentDetail: String!
        $cbAmount: Int!
        $cbPeriod: Int!
        $cbPaySuccess: Boolean
    ) {
        createPurchaseCoupon (
            cb_date: $cbDate
            cb_cellPhone: $cbCellPhone
            cb_price: $cbPrice
            cb_paymentType: $cbPaymentType
            cb_paymentDetail: $cbPaymentDetail
            cb_amount: $cbAmount
            cb_period: $cbPeriod
            cb_pay_success: $cbPaySuccess
        ) 
    }
`;

export const CREATE_PREMIUM_EXTEND_PAYMENT = gql`
    mutation createPremiumExtendPayment (
        $cellphone: String!
        $upayDate: String!
        $upayPeriod: Int!
        $upayPrice: Int!
        $upayPaymentType: String!
        $upayPaymentDetail: String!
        $upayPaySuccess: Boolean
    ) {
        createPremiumExtendPayment (
            cellphone: $cellphone
            upay_date: $upayDate
            upay_period: $upayPeriod
            upay_price: $upayPrice
            upay_paymentType: $upayPaymentType
            upay_paymentDetail: $upayPaymentDetail
            upay_pay_success: $upayPaySuccess
        )
    } 
`;

export const PREMIUM_AUTH_REQUEST = gql`
    mutation premiumAuthRequest (
        $cellphone: String 
    ) {
        premiumAuthRequest (
            cellphone: $cellphone
        )
    }
`;

export const CREATE_PURCHASE_COUPON_EXCEL = gql`
    mutation createPurchaseCouponExcel (
        $cbId: Int!
    ) {
        createPurchaseCouponExcel (
            cb_id: $cbId 
        ) {
            result
            couponExcel
        }
    }
`;