import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import phoneIcon from "../../assets/callingImage/phoneIcon.svg";

const EndButton = styled.button`
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 30px;
  border-radius: 50%;
  background-color: ${colors.redColor};
  flex-shrink: 0;
  cursor: pointer;
  
  &:active { 
    background-color: ${colors.deepRedColor};
  }
`;
const Image = styled.img`
    transform: rotate(135deg);
`;

const CallEndButton = ({ onClick }) => {
    return (
        <EndButton onClick={onClick}>
            <Image src={phoneIcon} />
        </EndButton>
    )
}

export default React.memo(CallEndButton);
