import React from "react";
import HomePresenter from "./HomePresenter";
import { Helmet } from "react-helmet";

const HomeContainer = () => {
  return (
    <>
      <Helmet>
        <title>시큐릿</title>
        <meta
          name="description"
          content="시큐릿 `안심 주차 번호판`"
        />
        <link rel="canonical" href="https://www.seqret.co.kr" />
      </Helmet>
      <HomePresenter />;
    </>
  );
};

export default HomeContainer;
