import React, {createContext, useCallback, useEffect, useRef} from "react";
import Router from "./Router";
import GlobalStyles from "./styles/GlobalStyles";
import useSbCalls from "./context/useSbCalls";
import calling from "./assets/audio/calling.mp3";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from "react-toastify";

export const CallInfoContext = createContext(null);

const App = () => {
    const {deAuth} = useSbCalls();
    const audio = useRef(new Audio(calling));
    audio.volume = 0.01;

    const soundPlay = useCallback(async () => {
        await audio.current.play();
    }, [audio]);
    const soundPause = useCallback(() => {
        audio.current.pause();
    },[audio]);

    const setScreenSize = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, []);

    useEffect(() => {
        setScreenSize();
        return () => deAuth();
    }, []);

    // useEffect(() => {`
    //     const qrCode = localStorage.getItem('qrCode');
    //     if (qrCode) {
    //         localStorage.removeItem('qrCode');
    //     }
    // }, []);

    // 콘솔 삭제
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.info = function no_console() {};

    return (
        <CallInfoContext.Provider value={{
            // callerInfo,
            soundPlay,
            soundPause
        }}>
            <ToastContainer position={toast.POSITION.TOP_RIGHT} />
            <GlobalStyles/>
            <Router/>
        </CallInfoContext.Provider>
    );
};

export default App;
