import { gql } from "@apollo/client";

export const SEND_CALL = gql`
    mutation sendCall (
        $receiver_user_id: Int
        $qr_id: Int
        $receiverSBId:String
        $callerSBId: String
    ) {
        sendCall (
            receiver_user_id: $receiver_user_id
            qr_id: $qr_id
            receiverSBId: $receiverSBId
            callerSBId: $callerSBId
        ) {
            error
            result
        }
    }
`;

export const SEND_CALL_MESSAGE = gql`
    mutation sendCallMessage(
        $receiverUserId: Int!
        $qrId: Int!
        $message: String!
    ) {
        sendCallMessage(
            receiver_user_id: $receiverUserId
            qr_id: $qrId
            message: $message
        )
    }
`;

export const CALL_END = gql`
    mutation callEnd (
        $ch_createdAt: String
        $callTime: Int
        $receiver_user_id: Int
        $qr_id: Int
        $receverSBId: String
        $callerSBId: String
    ) {
        callEnd (
            ch_createdAt: $ch_createdAt
            callTime: $callTime
            receiver_user_id: $receiver_user_id
            qr_id: $qr_id
            receverSBId: $receverSBId
            callerSBId: $callerSBId
        ) {
            result
            error
        }
    }
`;
