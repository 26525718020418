import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import DirectCall from "./pages/DirectCall";
import BuyCoupon from "./pages/BuyCoupon";
import ExtendPeriod from "./pages/ExtendPeriod";
import PaymentList from "./pages/PaymentList";
import PremiumList from "./pages/PremiumList";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/direct-call/*" element={<DirectCall />} />
      <Route path="/buyCoupon/*" element={<BuyCoupon />} />
      <Route path="/extendPeriod/*" element={<ExtendPeriod />} />
      <Route path="/paymentList/*" element={<PaymentList />} />
      <Route path="/premiumList/*" element={<PremiumList />} />
    </Routes>
  );
};

export default Router;
