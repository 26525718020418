import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import colors from "../../styles/colors";
import {IoRadioButtonOnOutline} from "react-icons/io5";
import {getTimeString, thousandSeparatorComma} from "../../utils/commons";
import {Link} from "react-router-dom";

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const ContentsBox = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 100px;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-bottom: 40px;

  @media only screen and (max-width: 1024px) {
    padding: 0px 50px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`;
const Title = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 12px;
  font-size: 22px;
  margin-bottom: 24px;
`;
const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 0px;
  align-items: center;
  height: 58px;
  border: 1px solid ${colors.lightGrayColor};
  @media only screen and (max-width: 768px) {
    padding: 0px 12px;
  }
`;
const LineTitle = styled.span`
  width: 220px;
  padding-left: 12px;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;
const LineInput = styled.input`
  width: 180px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${colors.grayFontColor};
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;
const LineSelect = styled.select`
  width: 180px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${colors.grayFontColor};
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;
const LineViewer = styled.div``;

const AuthButton = styled.div`
  margin-left: 12px;
  height: 36px;
  border-radius: 4px;
  background-color: ${colors.blackColor};
  width: 44px;
  color: ${colors.whiteColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const PayButton = styled.div`
  border-radius: 8px;
  height: 44px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({backGround}) => backGround ? colors.blackColor : colors.whiteColor};
  color: ${({backGround}) => backGround ? colors.whiteColor : colors.blackColor};
  border: 0px solid ${colors.blackColor};
  border-width: ${({backGround}) => (backGround ? "0px" : "1px")};
  font-size: 18px;
  margin-right: 12px;
  cursor: pointer;

  &:active {
    opacity: 0.6;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const RadioBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 12px;
  cursor: pointer;
`;
const LabelTitle = styled.span`
  font-weight: bold;
  margin-left: 12px;
  @media only screen and (max-width: 768px) {
    width: 40%;
  }
`;
const TimerText = styled.span`
  color: ${colors.redColor};
  margin-left: 20px;
`;

const ExtendPPresenter = ({
                              seconds,
                              isAuth,
                              authNumber,
                              setAuthNumber,
                              phoneNumber,
                              setPhoneNumber,
                              extendPeriod,
                              setExtendPeriod,

                              calcAmount,
                              onClickPayment,
                              payMethod,
                              setPayMethod,

                              sendAuthCode,
                              confirmAuthCode,
                          }) => {

    return (
        <>
            <Header/>
            <Wrapper>
                <ContentsBox>
                    <Title>프리미엄 연장하기</Title>
                    <LineWrapper>
                        <LineTitle>연장기간</LineTitle>
                        <LineSelect
                            value={extendPeriod}
                            onChange={(e) => setExtendPeriod(parseInt(e.target.value), 10)}
                        >
                            <option value={1}>1개월</option>
                            <option value={3}>3개월</option>
                            <option value={6}>6개월</option>
                            <option value={12}>12개월</option>
                        </LineSelect>
                    </LineWrapper>
                    <LineWrapper>
                        <LineTitle>금액</LineTitle>
                        <LineViewer>
                            {thousandSeparatorComma(calcAmount(extendPeriod))}
                            {/*{(extendPeriod * 16000)*/}
                            {/*  .toString()*/}
                            {/*  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}{" "}*/}원
                        </LineViewer>
                    </LineWrapper>
                </ContentsBox>
                <ContentsBox>
                    <Title>구매자 정보</Title>
                    <LineWrapper>
                        <LineTitle>휴대폰번호</LineTitle>
                        <LineInput
                            value={phoneNumber}
                            onChange={(e) =>
                                setPhoneNumber(
                                    e.target.value
                                        .replace(/[^0-9]/g, "")
                                        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                                        .replace(/(\-{1,2})$/g, "")
                                )
                            }
                        />
                        <AuthButton onClick={sendAuthCode}>전송</AuthButton>
                    </LineWrapper>
                    <LineWrapper>
                        <LineTitle>인증번호 입력</LineTitle>
                        <LineInput
                            value={authNumber}
                            onChange={(e) => setAuthNumber(e.target.value)}
                            type="number"
                            maxlength="6"
                        />
                        <AuthButton onClick={confirmAuthCode}>확인</AuthButton>
                        {(!!seconds && !isAuth) && <TimerText>{getTimeString(seconds)}</TimerText>}
                    </LineWrapper>
                </ContentsBox>
                <ContentsBox>
                    <Title>결제 방법</Title>
                    <LineWrapper>
                        <RadioBox onClick={() => setPayMethod('신용카드')}>
                            <IoRadioButtonOnOutline
                                color={payMethod === '신용카드' ? colors.blueColor : colors.lightGrayFontColor}
                                size={22}
                            />
                            <LabelTitle>신용카드</LabelTitle>
                        </RadioBox>

                        <RadioBox onClick={() => setPayMethod('카카오페이')}>
                            <IoRadioButtonOnOutline
                                color={payMethod === '카카오페이' ? colors.blueColor : colors.lightGrayFontColor}
                                size={22}
                            />
                            <LabelTitle>카카오페이</LabelTitle>
                        </RadioBox>
                    </LineWrapper>
                </ContentsBox>
                <ContentsBox>
                    <ButtonWrapper>
                        <PayButton onClick={onClickPayment} backGround>결제하기</PayButton>
                        <Link to='/premiumList'>
                            <PayButton>
                                연장내역 조회하기
                            </PayButton>
                        </Link>
                    </ButtonWrapper>
                </ContentsBox>
                <Footer/>
            </Wrapper>
        </>
    );
};

export default ExtendPPresenter;
